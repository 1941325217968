import React, { Component, Fragment } from 'react';

export default class Pago extends Component {
    constructor(props){
        super(props);
        this.form = React.createRef();

        this.state = {
            cartData: [],
            cardName: "",
            ccNumber: "",
            expMonth: "",
            expYear: "",
            ccv: "",
            errorMessage: "",
            name: "",
            lastname: "",
            phone: "",
            email: "",
            confEmail: "",
            checkBox: false,
            disablePagoButton: false,
            token_id: "",
            redirect: null
        };

        this.cardNameChange = this.cardNameChange.bind(this);
        this.ccNumberChange = this.ccNumberChange.bind(this);
        this.expMonthChange = this.expMonthChange.bind(this);
        this.expYearChange = this.expYearChange.bind(this);
        this.ccvChange = this.ccvChange.bind(this);
        this.nameChange = this.nameChange.bind(this);
        this.lastnameChange = this.lastnameChange.bind(this);
        this.phoneChange = this.phoneChange.bind(this);
        this.emailChange = this.emailChange.bind(this);
        this.confEmailChange = this.confEmailChange.bind(this);
        this.checkBoxChange = this.checkBoxChange.bind(this);
        this.onPaymentClick = this.onPaymentClick.bind(this);
        this.sucess_callbak = this.sucess_callbak.bind(this);
        this.error_callbak = this.error_callbak.bind(this);
        this.onSeguirComprando = this.onSeguirComprando.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            eventId: nextProps.eventId
        });
    }

    componentDidMount() {
        const script = document.createElement('script');
        script.src = "https://openpay.s3.amazonaws.com/openpay.v1.min.js";
        script.async = false;
        script.type = "text/javascript";
        document.body.appendChild(script);

        const script2 = document.createElement('script');
        script2.src = "https://openpay.s3.amazonaws.com/openpay-data.v1.min.js";
        script2.async = false;
        script2.type = "text/javascript" ;
        document.body.appendChild(script2);

        const script3 = document.createElement('script');
        script3.src = "https://ajax.googleapis.com/ajax/libs/jquery/1.11.0/jquery.min.js";
        script3.async = false;
        script3.type = "text/javascript";
        document.body.appendChild(script3);
    }

    cardNameChange = (event) => {
        this.setState({
            cardName: event.target.value
        });
    }

    ccNumberChange = (event) => {
        this.setState({
            ccNumber: event.target.value
        });
    }

    expMonthChange = (event) => {
        this.setState({
            expMonth: event.target.value
        });
    }

    expYearChange = (event) => {
        this.setState({
            expYear: event.target.value
        });
    }

    ccvChange = (event) => {
        this.setState({
            ccv: event.target.value
        });
    }

    nameChange = (event) => {
        this.setState({
            name: event.target.value
        });
    }

    lastnameChange = (event) => {
        this.setState({
            lastname: event.target.value
        });
    }

    phoneChange = (event) => {
        this.setState({
            phone: event.target.value
        });
    }

    emailChange = (event) => {
        this.setState({
            email: event.target.value
        });
    }

    confEmailChange = (event) => {
        this.setState({
            confEmail: event.target.value
        });
    }

    checkBoxChange = (event) => {
        this.setState({
            checkBox: event.target.checked
        })
    }

    onPaymentClick = (event) => {
        event.preventDefault();

        if(window.OpenPay !== undefined && window.OpenPay != null) {
            if(this.state.email !== "" && RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}/).test(this.state.email))
                if(this.state.email === this.state.confEmail)
                    if(this.state.name !== "" && this.state.lastname !== "")
                        if(this.state.phone !== "" && RegExp(/\d{10}|\d{11}|\d{12}|\d{13}|\d{14}/).test(this.state.phone))
                            if(this.state.cardName !== "")
                                if(RegExp(/\d{16}|\d{15}/).test(this.state.ccNumber))
                                    if(RegExp(/\d{2}/).test(this.state.expMonth))
                                        if(RegExp(/\d{2}/).test(this.state.expYear))
                                            if(RegExp(/\d{3}|\d{4}/).test(this.state.ccv))
                                                if(this.state.checkBox)
                                                {
                                                    this.setState({ disablePagoButton: true });
                                                    window.OpenPay.setId('mznpr1ofmtlih2af7fld');
                                                    window.OpenPay.setApiKey('pk_be72058fb12a48f8a3e798cf8f4206cc');
                                                    // window.OpenPay.setId('masxf5sopyqlu3tskcx8');
                                                    // window.OpenPay.setApiKey('pk_c723aa6224a646418afa846cb55b0644');
                                                    window.OpenPay.setSandboxMode(false);
                                                    window.OpenPay.deviceData.setup("payment-form", "deviceIdHiddenFieldName");
                                                    window.OpenPay.token.extractFormAndCreate('payment-form', this.sucess_callbak, this.error_callbak);
                                                }
                                                else
                                                    this.setState({ errorMessage: 'Debe marcar la casilla si ha le\xEDdo los t\xE9rminos y condiciones.'});
                                            else
                                                this.setState({ errorMessage: 'El c\xF3digo de seguridad debe ser de 3 o 4 d\xEDgitos.'});
                                        else
                                            this.setState({ errorMessage: 'El a\xF1o de expiraci\xF3n debe ser de 2 d\xEDgitos.'});
                                    else
                                        this.setState({ errorMessage: 'El mes de expiraci\xF3n debe ser de 2 d\xEDgitos'});
                                else
                                    this.setState({ errorMessage: 'El n\xFAmero de tarjeta debe ser de 15 o 16 d\xEDgitos.'});
                            else
                                this.setState({ errorMessage: 'El nombre del titular de la tarjeta es obligatorio.'});
                        else
                            this.setState({ errorMessage: 'El n\xFAmero de tel\xE9fono debe ser de 10 a 14 d\xEDgitos.'});
                    else
                        this.setState({ errorMessage: 'Indique el nombre y apellidos del contacto.'});
                else
                    this.setState({ errorMessage: 'No coincide el correo elect\xF3nico con la confimaci\xF3n.'})
            else
                this.setState({ errorMessage: 'El correo elect\xF3nico no es v\xE1lido.'});
        }
        else {
            this.setState({
                errorMessage: "Error OpenPay"
            })
        }
    }

    sucess_callbak = (response) => {
        const uId = localStorage.getItem("uuidMisTickets");
        var token_id = response.data.id;

        this.setState({
            token_id: token_id,
        }, () => {
            const formData = new FormData(this.form.current);
            var formObject = {};
            var str = [];
            formData.forEach(function(value, key){
                formObject[key] = (key === 'token_id') ? token_id : value;
                str.push(encodeURIComponent(key) + "=" + encodeURIComponent((key === 'token_id') ? token_id : value));
            });

            fetch(`https://v1.mistickets.com.mx/api/v2/ventas/${this.props.eventoId}/${uId}/pagar?${str.join("&")}`, {
                method: "POST"
            })
            .then(response => {
                if (response.ok) {
                    response.json().then(
                        (json) => {
                            window.location.href = json.data;
                        },
                        (error) => {
                            this.setState({
                                disablePagoButton: false,
                                message: error
                            });
                        }
                    );
                }
                if (response.status === 500) {
                    this.setState({
                        disablePagoButton: false,
                        errorMessage: "Contacte al banco emisor y verifique 3D Secure"
                    });
                }
            })
            .catch(error => console.error(error));
        });
    };

    error_callbak = (response) => {
        var desc = response.data.description !== undefined ? response.data.description : response.message;
        this.setState({
            errorMessage: "ERROR [" + response.status + "] " + desc,
            disablePagoButton: false
        });
    };

    onSeguirComprando = () => {
        this.props.onSeguirComprando(false);
    }

    render() {
        return (
            <Fragment>
                <form ref={this.form} acceptCharset="UTF-8" id="payment-form">
                    <div className="checkout-widget checkout-contact">
                        <div className="section-header-1">
                            <h5 className="title" style={{marginBottom: "0px", paddingBottom: "0px"}}>Información de Contacto</h5>
                        </div>
                        <div className="checkout-contact-form">
                            <div className="form-group">
                                <input type="text" placeholder="Nombre" required="required" name="nombre_cliente" value={this.state.name} onChange={this.nameChange} id="nombre_cliente" />
                            </div>
                            <div className="form-group">
                                <input type="text" placeholder="Apellidos" required="required" name="apellido_cliente" value={this.state.lastname} onChange={this.lastnameChange} id="apellido_cliente" />
                            </div>
                            <div className="form-group">
                                <input type="email" placeholder="Correo electrónico" required="required" name="correo_electronico" value={this.state.email} onChange={this.emailChange} id="correo_electronico" />
                            </div>
                            <div className="form-group">
                                <input type="email" placeholder="Confirma tu correo electrónico" required="required" name="correo_electronico" value={this.state.confEmail} onChange={this.confEmailChange} id="confcorreo_electronico" onPaste={(e)=>{e.preventDefault(); return false;}} />
                            </div>
                            <div className="form-group">
                                <input type="text" placeholder="Teléfono" required="required" maxLength="14" name="telefono_cliente" value={this.state.phone} onChange={this.phoneChange} id="telefono_cliente" />
                            </div>
                        </div>
                    </div>
                    <div className="checkout-widget checkout-card mb-0">
                        <div className="section-header-1">
                            <h5 className="title">Información de Pago</h5>
                        </div>
                        <h6 className="subtitle">Detalles de la Tarjeta Bancaria</h6>
                        <div className="payment-card-form">
                            <div className="form-group w-100">
                                <label htmlFor="card1">Número de Tarjeta</label>
                                <input value={this.state.ccNumber} onChange={this.ccNumberChange} maxLength="16" type="text" id="card_number" autoComplete="off" data-openpay-card="card_number" required="required" pattern="[0-9]{13,16}" />
                                <div className="right-icon">
                                    <i className="flaticon-lock"></i>
                                </div>
                            </div>
                            <div className="form-group w-100">
                                <label htmlFor="card2">Nombre <small>(Como aparece en la tarjeta)</small></label>
                                <input value={this.state.cardName} onChange={this.cardNameChange} type="text" id="holder_name" autoComplete="off" data-openpay-card="holder_name" required="required" />
                            </div>
                            <div className="form-group">
                                <label className="col-md-6" htmlFor="card3">Fecha de Vencimiento</label>
                                <input className="col-md-3" value={this.state.expMonth} onChange={this.expMonthChange} maxLength="2" type="text" id="expiration_month" placeholder="MM" data-openpay-card="expiration_month" required="required" />
                                <input className="col-md-3" value={this.state.expYear} onChange={this.expYearChange} maxLength="2" type="text" id="expiration_year" placeholder="AA" data-openpay-card="expiration_year" required="required" />
                            </div>
                            <div className="form-group">
                                <label className="col-md-3" htmlFor="card4">CVV</label>
                                <input className="col-md-3" value={this.state.ccv} onChange={this.ccvChange} maxLength="4" type="text" id="cvv2" placeholder="CVV" autoComplete="off" data-openpay-card="cvv2" required="required" />
                            </div>
                            <div className="form-group check-group">
                                <input type="checkbox" id="acepto_terminos" checked={this.state.checkBox} onChange={this.checkBoxChange} style={{visibility: "initial"}} />
                                <label htmlFor="card5" style={{pointerEvents: "none"}}>
                                    <span className="title">Acepto</span>
                                </label>
                                <span className="info" style={{paddingLeft: "50px"}}><small>He leído y acepto los <a href="/terminos" target="_blank">términos y condiciones</a> de MisTickets.</small></span>
                            </div>
                            <div className="form-group">
                                <input type="hidden" name="token_id" id="token_id" />
                                <button disabled={this.state.disablePagoButton} className="custom-button" id="pay-button" onClick={this.onPaymentClick}>Pagar</button>
                                <p className="notice">
                                    <span style={{fontSize: "14px", color: "red", fontWeight: "bold"}}>{this.state.errorMessage}</span>
                                </p>
                                <a href="#0" onClick={this.onSeguirComprando} style={{color: "#31d7a9", paddingTop: "10px"}}><div style={{WebkitTransform: "rorate(180deg)", transform: "rotate(180deg)", float: "left", paddingLeft: "10px"}}><i class="flaticon-double-right-arrows-angles"></i></div>Seguir comprando</a>
                            </div>
                        </div>
                    </div>
                </form>
            </Fragment >
        )
    }
}